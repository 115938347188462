<template>
    <div class="member-box">
        <div class="left-nav" :class="{hide:isHide}">
            <div class="scroll-box">
                <div class="nav">
                    <div class="li" :class="{selected:navIndex==index}"  v-for="(item,index) in reportNavData" :key="item">
                        <div class="title verticalCenter" @click="navClick(index)">
                            <i class="iconfont" :class="item.icon"></i>
                            {{item.title}}
                        </div>
                        <el-collapse-transition>
                            <div class="children-nav" v-show="navIndex==index">
                                <div class="li" 
                                v-for="childre in item?.childre" :key="childre"
                                :class="{selected:navChildreIndex==childre.key}" 
                                @click="addFrame(childre)"
                                >{{childre.title}}</div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
            <div class="more-bnt" @click="isHide=!isHide">
                <i class="iconfont icon-arrow-right" v-if="isHide"></i>
                <i class="iconfont icon-arrow-left" v-else></i>
            </div>
        </div>
        <div class="right-frame-box">
            <tabs class="frame-tabs" v-model="frameIndex">
                <div class="frame-nav">
                    <div class="scroll-box" v-mouse-scroll>
                        <tab-nav class="li" v-for="(item,index) in tabsFrameData" :key="item">
                            {{item.navTitle}}<i class="ico-close iconfont" @click.stop="delFrame(index)">×</i>
                        </tab-nav> 
                    </div>
                </div>
                <div class="frame-pane">
                    <tab-pane v-for="(item,index) in tabsFrameData" :key="item">
                        <div class="component-box" v-if="!item.isHttp">
                            <component v-if="item.frameUrl" :key="item.componentKey" v-on:reload="item.componentKey=!item.componentKey" :is="item.frameUrl" :data="item.data" v-on:close="delFrame(index)"></component>
                            <el-empty v-else description="加急开发中,请耐心等待"></el-empty>
                        </div>
                        
                        <iframe v-if="item.isHttp" :src="item.frameUrl"></iframe>
                    </tab-pane>
                </div>
            </tabs>
            
        </div>
        <key-board-hang />
    </div>
</template>

<script>
import memberModel from './memberModel'

export default {
    components:memberModel.components,
    name:"member",
    data(){
        return {
            /**左边导航是否隐藏 */
            isHide:false,
            navIndex:null,
            navChildreIndex:null,
            reportNavData:[],
            /**报表一级菜单数据 */
            reportNavItems:[
                {key:'Card-Operate',title:"会员卡操作",icon:'icon-yonghuxinxiguanli'},
                {key:'Card-Report',title:"会员业务报表",icon:'icon-yewuliucheng'}
            ],
            /**报表二级菜单数据 */
            reportNavSubItems:[
                {key:'MakeCard',title:"制卡",url:"businessCardMember"},
                {key:'OpenCard',title:"会员办卡",url:"membershipCard"},
                {key:'CardRecharge',title:"会员充值",url:"memberRecharge"},
                {key:'CardAssetChange',title:"卡资产调整",url:"cardAssetChange"},
                {key:'CardLost',title:"挂失",url:"cardLostOrStop"},
                {key:'CardStop',title:"卡停用",url:"cardLostOrStop",data:{type:2}},
                {key:'CardReturn',title:"退卡",url:"cardReturn"},
                {key:'CardPwdModify',title:"修改密码",url:"cardPwdModify"},
                {key:'MemberInfoModify',title:"修改会员信息",url:"memberInfoModify"},
                {key:'CardReissue',title:"补卡",url:"cardReissue"},
                {key:'CardBind',title:"卡绑定",url:"CardBind"},
                {key:'CardReleaseBind',title:"卡解绑",url:"CardReleaseBind"},
                {key:'MemberInfoCheckCoupon',title:"手动核销券",url:"memberInfoCheckCoupon"},
                {key:'CardConsumeReport',title:"会员卡消费明细表",url:"cardConsumeReport"},
                {key:'CardRechargeReport',title:"会员卡充值明细表",url:"cardRechargeReport"},
                {key:'CardDayReport',title:"会员交易汇总表",url:"cardDayReport"},
                {key:'CardTrackReport',title:"会员卡交易轨迹表",url:"cardTrackReport"},
                {key:'NewMemberReport',title:"会员新增统计报表",url:"newMemberReport"},
            ],
            tabsFrameData:[],
            frameIndex:0,
            memberPay:'',
            orderInfo:''
        }
    },
    mounted(){
        this.$emit("navIndex",2);
        this.$nextTick(()=>{
            /**报表菜单数据 */
            this.$cacheData.SysProjects?.get()?.find(t=>t.Project_Key=="Member")
            ?.SysProjectGroups.find(t=>t.Group_Key=='Member_CloudPos' && t.SubGroup_YN)
            ?.SysProjectItems?.forEach(item => {
                if(item.Is_Show){
                    let nav=this.reportNavItems.find(t=>t.key==item.ProjectItem_Key);//一级菜单
                    if(nav){
                        let children=[];
                        item.SysProjectSubItems.forEach((subItem)=>{//二级菜单
                            if(subItem.Is_Show){
                                this.reportNavSubItems.find((childreNav)=>{
                                    if(childreNav.key==subItem.ProjectSubItem_Key){
                                        let data=Object.assign({},childreNav,{title:subItem.ProjectSubItem_Name});
                                        children.push(data);
                                        return true;
                                    }
                                    return false;
                                })
                            }
                        })
                        this.reportNavData.push(Object.assign({},nav,{title:item.ProjectItem_Name,childre:children}));
                    }
                }
            });
            this.memberPay = this.$route.params.memberPay
            if(this.memberPay!=undefined){
                this.navIndex=0
                this.addFrame(this.reportNavData[0].childre[2])
                console.log('不为空')
            }else{
                console.log('空')
            }
        });
    },
    watch:{
        frameIndex(newValue){
            this.navChildreIndex=this.tabsFrameData[newValue]?.key;
        }
    },
    methods:{
        navClick(index){
            if(this.navIndex!=index){
                this.navIndex=index;
            }else{
                this.navIndex=null;
            }
        },
        delFrame(index){
            this.tabsFrameData?.splice(index,1);
            if(index==this.frameIndex){
                if(this.frameIndex>0){
                    this.frameIndex--;
                }
                this.navChildreIndex=this.tabsFrameData[this.frameIndex]?.key;
            }
        },
        addFrame(data){
            this.navChildreIndex=data.key;
            let index= this.tabsFrameData?.findIndex((item)=>item.key==data.key);
            if(index>=0){
                this.frameIndex=index;
            }else{
                if(this.tabsFrameData.length>=10){
                    this.$message.warning('页签最多开10个,请先清除不用的在打开');
                    return;
                }
                let item={key:data.key,navTitle:data.title,frameUrl:'',isHttp:false,data:data.data};
                if(/^http/g.test(data.url)){
                    item.frameUrl=data.url;
                    item.isHttp=true;
                }else{
                    item.frameUrl=memberModel.getComponent(data.url);
                }
                this.tabsFrameData.push(item);
                this.frameIndex=this.tabsFrameData.length-1;
            }
        }
    }
}
</script>

<style lang='scss'>
@import "./member.scss";
</style>